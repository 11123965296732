import { Box, Button, CircularProgress, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAppSelector } from 'commons/store/hooks';
import { selectCurrentSection, selectEcrf } from 'features/ecrf/redux/ecrfSlice';
import React, { useState } from 'react';
import {
    StyledEcrfPanel,
    StyledEcrfTitle,
    StyledEcrfSubTitle,
    StyledEcrfSectionsTitle,
    StyledEcrfSectionsTitleWrapper,
    StyledEcrfSectionsWrapper,
    StyledEcrfTitleFull,
    StyledButtonShow,
    StyledEcrfTitleSmall,
    StyledEcrfTitleRand,
    StyledEcrfSubTitleSmall,
} from '../Ecrf.styled';
import { MenuItem } from './MenuItem';
import DateTime from "core/helpers/date/DateTime";
import { COLOR_SECONDARY_DARK, DATETIME_FORMAT } from "commons/constants/env";
import { roles } from 'commons/auth/roles';
import { selectUserProfile } from "core/redux/userSlice";
import {useTranslation} from "react-i18next";

interface Props {
    selectedVisit: any;
    selectedSection: any;
    setSelectedSection: (selectedSection: any) => void;
    isDataLoading: boolean;
    setIsDataLoading: (isDataLoading: boolean) => void;
}

export const ecrfStatusDictionary = [
    'active',
    'suspended',
    'excluded',
    'randomized',
    'signed',
    'completed',
    'screen-failure',
    'blocked'
];

export const LeftPanel: React.FC<Props> = ({
   selectedVisit,
   selectedSection,
   isDataLoading,
   setIsDataLoading,
   setSelectedSection
}) => {
    /** This is current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    /** Current ecrf all data */
    const ecrf = useAppSelector(selectEcrf);

    /** Current user profile */
    const userProfile = useAppSelector(selectUserProfile);

    const [expandSections, setExpandSections] = useState<boolean>(true);
    const [showPanel, setShowPanel] = useState(false)
    const { t } = useTranslation();

    /** Display count elements */
    const displayCount = () => {
        return userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.control.includes(role)
        );
    }

    return (
        <StyledEcrfPanel show={showPanel}>
            <StyledButtonShow>
                <Button onClick={() => setShowPanel(!showPanel)} variant={'outlined'}
                    style={{position: 'fixed', bottom: '20px', right: '10px'}}>
                    {showPanel ? t('hide') : t('show')}
                </Button>
            </StyledButtonShow>

            <Box>
                <Box>
                    <StyledEcrfTitle>{t('medical-center')}</StyledEcrfTitle>
                    <StyledEcrfSectionsTitle>{ecrf?.medicalCenter?.name || '-'}</StyledEcrfSectionsTitle>
                </Box>
                <Box mt="15px">
                    <StyledEcrfTitle><b>{t('screening-number')}</b> / {t('randomization')}</StyledEcrfTitle>
                    <StyledEcrfSubTitle>
                        {ecrf?.screeningNumber || '-'} / <StyledEcrfTitleRand>{ecrf?.randomId || '-'}</StyledEcrfTitleRand>
                    </StyledEcrfSubTitle>
                </Box>
                <Box mt="15px">
                    <StyledEcrfTitle>{t('randomization-date')}</StyledEcrfTitle>
                    <StyledEcrfSubTitle>{DateTime.toIso(ecrf?.randomAt, DATETIME_FORMAT) || '-'}</StyledEcrfSubTitle>
                </Box>
                <Box>
                    <StyledEcrfTitleSmall>{t('created-date')}</StyledEcrfTitleSmall>
                    <StyledEcrfSubTitleSmall>{DateTime.toIso(ecrf?.createdAt, DATETIME_FORMAT) || '-'}</StyledEcrfSubTitleSmall>
                </Box>
            </Box>

            <Box mt="15px" pt={'15px'} style={{borderTop: `1px solid ${COLOR_SECONDARY_DARK}`}}>
                <StyledEcrfTitle>{t('visible')}: {currentSection?.name}</StyledEcrfTitle>
            </Box>

            <Box mt="15px">
                <StyledEcrfTitle>{t('patient-status')}: {t(ecrfStatusDictionary[ecrf?.status])}</StyledEcrfTitle>
            </Box>

            <Box mt="15px" pt={'15px'} style={{borderTop: `1px solid ${COLOR_SECONDARY_DARK}`}}>
                <StyledEcrfTitleFull
                    onClick={() => window.open(`${process.env.REACT_APP_API_URL}/api/ecrves/${ecrf?.id}/export.xlsx`, "_blank")}>
                    {t('export-to')} .xlsx
                </StyledEcrfTitleFull>
            </Box>

            <Box mt={5}>
                {selectedVisit?.sections && (
                    <>
                        <StyledEcrfSectionsTitleWrapper onClick={() => setExpandSections(!expandSections)}>
                            <StyledEcrfSectionsTitle>{t('sections')}</StyledEcrfSectionsTitle>
                            {isDataLoading ? (
                                <CircularProgress color="inherit" size={12} />
                            ) : (
                                <> {expandSections ? <ExpandLess /> : <ExpandMore />} </>
                            )}
                        </StyledEcrfSectionsTitleWrapper>
                    </>
                )}

                <Box mt="20px">
                    <Collapse in={expandSections} unmountOnExit>
                        <StyledEcrfSectionsWrapper>
                            {selectedVisit?.sections && (
                                <Box>
                                    {selectedVisit?.sections.map((section: any) => (
                                        <MenuItem
                                            key={section?.id}
                                            section={section}
                                            selectedSection={selectedSection}
                                            setSelectedSection={setSelectedSection}
                                            isDataLoading={isDataLoading}
                                            setIsDataLoading={setIsDataLoading}
                                            displayCount={displayCount()}
                                        />
                                    ))}
                                </Box>
                            )}
                        </StyledEcrfSectionsWrapper>
                    </Collapse>
                </Box>
            </Box>
        </StyledEcrfPanel>
    );
};
